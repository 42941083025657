/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on June 9, 2022 */



@font-face {
    font-family: 'mahaloregular';
    src: url('mahalo-webfont.woff2') format('woff2'),
         url('mahalo-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'aquaticoregular';
    src: url('aquatico-regular-webfont.woff2') format('woff2'),
         url('aquatico-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Bold.woff2') format('woff2'),
        url('Calibre-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-BlackItalic.woff2') format('woff2'),
        url('Calibre-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Black.woff2') format('woff2'),
        url('Calibre-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Light.woff2') format('woff2'),
        url('Calibre-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-LightItalic.woff2') format('woff2'),
        url('Calibre-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Medium.woff2') format('woff2'),
        url('Calibre-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-RegularItalic.woff2') format('woff2'),
        url('Calibre-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-ThinItalic.woff2') format('woff2'),
        url('Calibre-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Thin.woff2') format('woff2'),
        url('Calibre-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Semibold.woff2') format('woff2'),
        url('Calibre-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-Regular.woff2') format('woff2'),
        url('Calibre-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-MediumItalic.woff2') format('woff2'),
        url('Calibre-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-SemiboldItalic.woff2') format('woff2'),
        url('Calibre-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre';
    src: url('Calibre-BoldItalic.woff2') format('woff2'),
        url('Calibre-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

