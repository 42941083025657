body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --shadow: #11b2d7;
  --scrollbarBG: #001430;
  --thumbBG: #81f1fd;
}
::-webkit-scrollbar {
  width: 16px;
}
::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  box-shadow: 0 -100vh 0 100vh var(--shadow), 0 0 15px 5px black;
}
.side-scroll {
  display: flex;
  overflow: auto;
  padding-bottom: 5px;
}

.side-scroll::-webkit-scrollbar-thumb:horizontal {
  background-color: var(--thumbBG);
  /* box-shadow: 10vh 0 0 20vh var(--shadow), 0 0 15px 5px black; */
}

.heading {
  font-size: 12px;
  text-decoration: none;
}
.heading:hover {
  color: white;
}
a:hover {
  color: white;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes slideinRight {
  from {
    margin-right: -500%;
  }

  to {
    margin-left: 0%;
  }
}
@keyframes slideinLeft {
  from {
    margin-left: -500%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slideUp {
  from {
    height: 0%;
    margin-top: 300%;
  }

  to {
    height: 100%;
  }
}

.portfolio-item {
  display: flex;
  padding-bottom: 3rem;
  align-items: center;
  justify-content: center;
}

.portfolio-item:last-child {
  margin-bottom: 0;
}

.portfolio-img {
  flex: 1;
}

.portfolio-description {
  flex: 1;
}


.portfolio-description h6 {
  font-size: 1.5rem;
 



}

.portfolio-description .programming {
  display: inline-block;
  margin-top: 2.5rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: color 650ms;
}

.portfolio-description .graphicdesign {
  display: inline-block;
  margin-top: 2.5rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: color 650ms;
}

.portfolio-description .cad {
  display: inline-block;
  margin-top: 2.5rem;
  font-size: 1.5rem;
  text-transform: uppercase;
  transition: color 650ms;
}

.portfolio-description .programming:hover {
  color: #c778eb;
}

.portfolio-description .graphicdesign:hover {
  color: #4fd6f5;
}

.portfolio-description .cad:hover {
  color: #e5674e;
}
