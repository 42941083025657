body {
    display: grid;
    height: 100vh; 
    place-items: center;
    background: #001430;
  }
  
  .dashboard {
    display: flex;
  }
  
  .cicle_svgs {
    width: 114px;
    height: 114px;
    margin: 1em;
  }
  
  .bg {
    fill: none;
    stroke-width: 10px;
    stroke: #0A0B0F;
  }
  
  [class^="meter-"] {
    fill: none;
    stroke-width: 6px;
    stroke-linecap: round;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    
  }
  
  .meter-1 {
    stroke-dasharray: 360;
    stroke-dashoffset: 100;
    stroke: #81F1FD;
    animation: progress-1 1s ease-out;
  }
  .meter-2 {
    stroke: #81F1FD;
    stroke-dasharray: 360;
    stroke-dashoffset: 160;
    animation: progress-2 .75s ease-out;
  }
  .meter-3 {
    stroke: #81F1FD;
    stroke-dasharray: 360;
    stroke-dashoffset: 250;
    animation: progress-3 .5s ease-out;
  }
  
  @keyframes progress-1 {
      from {
          stroke-dashoffset: 360;
      }
      to {
          stroke-dashoffset: 100;
      }
  }
  
  @keyframes progress-2 {
    from {
      stroke-dashoffset: 360;
    }
    to {
      stroke-dashoffset: 160;
    }
  }
  
  @keyframes progress-3 {
    from {
      stroke-dashoffset: 360;
    }
    to {
      stroke-dashoffset: 250;
    }
  }

  